input.highlight {
    border: 1px solid #eb516d;
}

select.highlight {
    border: 1px solid #eb516d;
}

div.driverCard:has(div.show) {
    height: 100%;
}

/* https://stackoverflow.com/questions/55264524/make-quilljs-editor-height-100 */
.ql-container {
    min-height: 50vh;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
}

@media (min-width: 768px){
.sidebar .nav-item .nav-link span {
    font-size: .94rem;
    display: inline;
}
}